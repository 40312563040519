<div class="omg-dialog-container" style="width: 560px" omgTheme>
  <div omgDialogTitleDirective>
    <h5 class="move-message-title">Move Message Thread</h5>
    <button omgButton variant="none" class="om-icon icon-close clickable" (click)="onClose()"></button>
  </div>
  <div omgDialogContentDirective>
    <form omgForm name="move_message_form" class="move_message_form">
      <div class="om-animate padding-normal" om-layout="vertical" om-layout-fill>
        <div class="label" om-layout om-layout-align="space-between">Where would you like to move this thread to?</div>
        <input
          omgInputText
          placeholder="Find by patient chart link or patient ID"
          name="newPatientUserInput"
          [(ngModel)]="newPatientUserInput"
          (ngModelChange)="findNewPatient()"
        />

        <div class="move_message_warning" *ngIf="newPatient">
          <span>Message thread, attachments, and internal comments will be moved to this chart:</span>
          <div class="new_patient_info">
            <omg-profile-image-bubble
              [url]="newPatient.profileImageUrl"
              [initials]="newPatient.initials"
              [colorCombo]="newPatient.id"
            ></omg-profile-image-bubble>
            <div class="new_patient_text inline">
              <span class="new_patient_name block">{{ newPatient.preferredName }} {{ newPatient.lastName }}</span>
              <span class="new_patient_details">
                {{ newPatient.dateOfBirth | omgDate: '4y' }} | {{ newPatient.id }} |
                {{
                  newPatient.contactEmail?.address ||
                    'No
                contact email on file'
                }}
              </span>
            </div>
          </div>
        </div>

        <div class="om-messages -warning" *ngIf="newPatientError">
          {{ newPatientError }}
        </div>
      </div>
    </form>
  </div>
  <div omgDialogActionsDirective>
    <button omgButton variant="primary" type="submit" [disabled]="!newPatient" (click)="onMoveToAnotherChart()">
      Move Message
    </button>
  </div>
</div>
